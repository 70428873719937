import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductItem = _resolveComponent("ProductItem")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_openBlock(), _createBlock(_component_ion_row, { class: "productsListRow" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product, prodIndex) => {
        return (_openBlock(), _createBlock(_component_ion_col, {
          key: prodIndex,
          class: "productListCol",
          size: _ctx.getColSize(_ctx.columnsSize),
          "size-lg": _ctx.getColSize(_ctx.columnsSize),
          "size-sizeXl": _ctx.getColSize(_ctx.columnsSize),
          "size-xs": "12",
          "size-sm": "6"
        }, {
          default: _withCtx(() => [
            (_ctx.itemType(product) != 'string')
              ? (_openBlock(), _createBlock(_component_ProductItem, {
                  key: 0,
                  initProduct: product,
                  addToCartIcon: true,
                  logSearch: _ctx.logSearchActive,
                  searchQuery: _ctx.searchQueryText
                }, null, 8, ["initProduct", "logSearch", "searchQuery"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["size", "size-lg", "size-sizeXl"]))
      }), 128))
    ]),
    _: 1
  }))
}