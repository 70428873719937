
import { defineComponent } from "vue";
import { IonRow, IonCol } from "@ionic/vue";
import { useStore } from "@/store";
import ProductItem from "../components/ProductItem.vue";

export default defineComponent({
  name: "ProductsList",
  props: {
    initProducts: Array || Object,
    columnsNumber: Number,
    logSearch: Boolean,
    searchQuery: String,
  },
  setup(props) {
    const storeX = useStore();

    const itemType = (item) => {
      return typeof item;
    };

    const getColSize = (columns: any) => {
      const column = 12 / columns;
      return column;
    };

    return {
      storeX,
      products: props.initProducts,
      columnsSize: props.columnsNumber,
      logSearchActive: props.logSearch,
      searchQueryText: props.searchQuery,
      getColSize,
      itemType,
    };
  },
  components: {
    IonRow,
    IonCol,
    ProductItem,
  },
});
