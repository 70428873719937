
import { defineComponent, ref, reactive } from "vue";
import {
	IonPage,
	IonContent,
	IonText,
	IonItem,
	IonRadioGroup,
	IonRadio,
	IonIcon,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	onIonViewWillEnter,
} from "@ionic/vue";
import { funnel, cellular, close } from "ionicons/icons";
import settingsApp from "@/libs/settings";
import httpApp from "@/libs/http";
import { useI18n } from "vue-i18n";
import { useLocalStorage } from "@/libs/localStorage";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import MainHeader from "../components/MainHeader.vue";
import MainFooter from "../components/MainFooter.vue";
import ProductsList from "../components/ProductsList.vue";
import Slider from "../components/Slider.vue";
import Banner from "../components/Banner.vue";
//import { conditionalExpression } from "@babel/types";

export default defineComponent({
	name: "Category",
	props: ["category_id"],
	setup(props) {
		const { t, te } = useI18n({ useScope: "global" });
		const { getLocalStorageData } = useLocalStorage();
		const { createLoading, openToast } = useGlobalFunctions();
		const router = useRouter();
		//const route = useRoute();
		const storeX = useStore();

		const opensortby = ref(false);
		const categoryId = ref(props.category_id);
		const category = ref();
		const gettingSub = ref(true);
		const gettingProducts = ref(false);
		const subCategories = ref([]);
		const products = ref([]);

		const pages = ref(1);
		const currentPage = ref(0);
		const allProductsCount = ref(0);
		const sortBy = ref("price");
		const sortDir = ref("ASC");
		const filters = ref({});
		const selectedFilters = ref({});
		const selectedFiltersLabels = ref({});
		const availableFilters = ref([]);
		const maxFilterItems = ref(3);
		const sortAttributes = ref(null);

		const icons = reactive({
			funnel,
			cellular,
			close,
		});

		const setSubCategories = () => {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("categorySubById"), {
					store_view: getLocalStorageData("storeCode"),
					category_id: categoryId.value,
				})
				.then(async (response: any) => {
					const resp = response;
					if (Object.prototype.hasOwnProperty.call(resp, "items")) {
						subCategories.value = resp.items;
					} else {
						subCategories.value = [];
					}
					gettingSub.value = false;
				})
				.catch((error) => {
					console.log(error);
					gettingSub.value = false;
				});
		};

		const getCategory = () => {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("categoryById"), {
					store_view: getLocalStorageData("storeCode"),
					id: categoryId.value,
				})
				.then(async (response: any) => {
					const resp = response;

					category.value = resp.data;
				})
				.catch((error) => {
					console.log(error);
				});
		};

		const getProducts = async (event?: any, emptyProducts = false) => {
			if (emptyProducts) {
				products.value = [];
				currentPage.value = 0;
				allProductsCount.value = 0;
			}
			if (gettingProducts.value) {
				return;
			}
			gettingProducts.value = true;
			const loading = await createLoading(t("pleaseWait"));
			currentPage.value = currentPage.value + 1;
			if (currentPage.value > pages.value) {
				if (event) {
					event.target.complete();
				}
				return;
			}
			if (!event) {
				loading.present();
			}

			const params = {
				store_view: getLocalStorageData("storeCode"),
				category_id: categoryId.value,
				get_stock: true,
				filters: filters.value,
				sort_by: sortBy.value,
				sort_dir: sortDir.value,
				page: currentPage.value,
				review_summary: true,
				limit: 40,
			};

			Object.assign(params, filters.value);
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("productsListByCategoryAndFilters"),
					params
				)
				.then(async (response: any) => {
					const resp = response;
					gettingProducts.value = false;
					if (!Object.prototype.hasOwnProperty.call(resp, "error")) {
						pages.value = Number(resp.pager.pages);
						currentPage.value = Number(resp.pager.current_page);
						allProductsCount.value = Number(resp.pager.items_count);
						if (Number(resp.pager.pages) == Number(resp.pager.current_page)) {
							if (event) {
								event.target.complete();
							}
						}
						resp.items.forEach((item: any) => {
							products.value.push(item);
						});
					}

					if (event) {
						event.target.complete();
					} else {
						loading.dismiss();
					}
				})
				.catch((error) => {
					if (event) {
						event.target.complete();
					} else {
						loading.dismiss();
					}
					gettingProducts.value = false;
					console.log(error);
				});
		};

		const getImageUrl = (category: any) => {
			if (category.image) {
				return (
					getLocalStorageData("websiteUrl") +
					"media/catalog/category/" +
					category.image_url
				);
			} else {
				return "assets/images/full_logo.jpeg";
			}
		};

		const getBannerImageUrl = (category: any) => {
			let image;
			if (category.category_banner) {
				image =
					getLocalStorageData("websiteUrl") +
					"media/catalog/category/" +
					category.category_banner;
				return "url(" + image + ") no-repeat center center";
			} else {
				return false;
			}
		};

		const goToProduct = (product: any) => {
			router.push({
				name: "Product",
				params: {
					productId: product.entity_id,
				},
			});
		};

		const navToHome = () => {
			router.push("/");
		};

		const getAvailableListSorting = () => {
			httpApp
				.sendGetRequest(settingsApp.getEndpointUrl("getAvailableListSorting"))
				.then(async (response: any) => {
					const resp = response;
					if (!Object.prototype.hasOwnProperty.call(resp, "error")) {
						sortAttributes.value = resp.data;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		};

		const getLayredNavAttributes = () => {
			if (categoryId.value) {
				const params = {
					category_id: categoryId.value,
					store_view: storeX.state.storeCode,
				};
				Object.assign(params, selectedFilters.value);
				httpApp
					.sendPostRequest(
						settingsApp.getEndpointUrl("getLayredNavAttributes"),
						params
					)
					.then(async (resp: any) => {
						availableFilters.value = [];
						if (resp) {
							if (!Object.prototype.hasOwnProperty.call(resp, "error")) {
								resp.data.forEach((item: any) => {
									if (item.code == "manufacturer") {
										item["show_more"] = item.items.length > 7 ? true : false;
										item["show_less"] = item.items.length > 7 ? false : true;
										item["sliced_items"] = item.items.slice(0, 7);
									} else {
										item["show_more"] =
											item.items.length > maxFilterItems.value ? true : false;
										item["show_less"] =
											item.items.length > maxFilterItems.value ? false : true;
										item["sliced_items"] = item.items.slice(
											0,
											maxFilterItems.value
										);
									}
									availableFilters.value.push(item);
								});
							}
						}
					})
					.catch((error) => {
						console.log(error, "getLayredNavAttributes");
					});
			}
		};

		const getFilterItems = (filter) => {
			if (filter["show_more"]) {
				return filter["sliced_items"];
			}
			return filter["items"];
		};

		const changeCategory = (event: any) => {
			categoryId.value = event.target.value;
			currentPage.value = 0;
			allProductsCount.value = 0;
			pages.value = 1;
			sortBy.value = "price";
			sortDir.value = "ASC";
			filters.value = {};
			gettingSub.value = true;
			getCategory();
			setSubCategories();
			getProducts(null, true);
			getLayredNavAttributes();
		};

		const updateQuery = () => {
			const url = window.location.href;
			const params = new URLSearchParams(selectedFilters.value);
			if (sortBy.value !== "price") {
				params.set("sort_by", sortBy.value);
				params.set("sort_dir", sortDir.value);
			}
			const newUrl = url.split("?")[0] + "?" + params.toString();
			window.history.pushState({}, "", newUrl);
		};

		const sortingAction = (title, direction) => {
			sortBy.value = title;
			sortDir.value = direction;

			currentPage.value = 0;
			pages.value = 1;
			updateQuery();
			getProducts(null, true);
		};

		const filterAction = () => {
			filters.value = selectedFilters.value;
			currentPage.value = 0;
			allProductsCount.value = 0;
			pages.value = 1;
			updateQuery();
			getProducts(null, true);
			getLayredNavAttributes();
		};

		const filterChanged = (event, filter) => {
			if (event.target.value) {
				filterAction();
				selectedFiltersLabels.value[filter.request_var] = {
					label: filter.store_label,
					value: event.target.value,
					valueLabel: filter.items.find(
						(filterItem) => filterItem.value == event.target.value
					).label,
				};
			} else {
				delete selectedFilters.value[filter.request_var];
				updateQuery();
			}
		};

		const cancelFilterAction = () => {
			sortBy.value = "price";
			sortDir.value = "ASC";
			filters.value = {};
			selectedFilters.value = {};
			selectedFiltersLabels.value = {};
			currentPage.value = 0;
			allProductsCount.value = 0;
			pages.value = 1;
			getProducts(null, true);
			getLayredNavAttributes();
			updateQuery();
		};

		const clearFilterItem = (selectedFilterCode) => {
			delete selectedFilters.value[selectedFilterCode];
			delete selectedFiltersLabels.value[selectedFilterCode];

			if (gettingProducts.value) {
				return;
			}

			currentPage.value = 0;
			allProductsCount.value = 0;
			pages.value = 1;
			getProducts(null, true);
			getLayredNavAttributes();
			updateQuery();
		};

		const getUrl = (path) => {
			const url = "/" + storeX.state.storeCode + "/" + path;
			return url;
		};

		const getFiltersFromUrl = () => {
			// get url
			const url = window.location.href;
			const params = new URLSearchParams(url.split("?")[1]);
			const paramsObj = Object.fromEntries(params.entries());

			if (paramsObj) {
				if (paramsObj.sort_by) {
					sortBy.value = paramsObj.sort_by;
					sortDir.value = paramsObj.sort_dir;
				} else {
					sortBy.value = "price";
					sortDir.value = "ASC";
				}
				selectedFilters.value = paramsObj;
				filters.value = paramsObj;

				if (categoryId.value) {
					const params = {
						category_id: categoryId.value,
						store_view: storeX.state.storeCode,
					};
					httpApp
						.sendPostRequest(
							settingsApp.getEndpointUrl("getLayredNavAttributes"),
							params
						)
						.then(async (resp: any) => {
							if (resp) {
								const allFilters = resp.data;

								Object.keys(selectedFilters.value).forEach((key) => {
									const filter = allFilters.find(
										(filter) => filter.request_var == key
									);
									if (filter) {
										selectedFiltersLabels.value[key] = {
											label: filter.store_label,
											value: selectedFilters.value[key],
											valueLabel: filter.items.find(
												(filterItem) =>
													filterItem.value == selectedFilters.value[key]
											).label,
										};
									}
								});
							}
						})
						.catch((error) => {
							console.log(error, "getLayredNavAttributes");
						});
				}
			} else {
				selectedFilters.value = {};
			}
		};

		const notifyEmptyActive = ref(
			getLocalStorageData("emptycategorysubscriptionActive")
		);

		const categoryNotifyHasProductsEmail = ref();

		if (storeX.state.customerData) {
			categoryNotifyHasProductsEmail.value =
				storeX.state.customerData.account_data.email;
		}

		const categoryNotifyHasProducts = async () => {
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("categoryNotifyHasProducts"),
					{
						category_id: categoryId.value,
						email: categoryNotifyHasProductsEmail.value,
					}
				)
				.then((resp: any) => {
					loading.dismiss();
					if (resp.success) {
						openToast(t("done"), "success");
					} else {
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
					}
				})
				.catch((error) => {
					console.log(error, "login error");
					loading.dismiss();
				});
		};

		onIonViewWillEnter(() => {
			categoryId.value = props.category_id;
			currentPage.value = 0;
			allProductsCount.value = 0;
			pages.value = 1;
			filters.value = {};
			selectedFiltersLabels.value = {};
			gettingSub.value = true;
			getCategory();
			setSubCategories();
			getBannerImageUrl(category);
			getAvailableListSorting();
			getFiltersFromUrl();
			getLayredNavAttributes();
			getProducts(false, true);
		});

		function doRefresh(refresherEvent: any) {
			currentPage.value = 0;
			allProductsCount.value = 0;
			pages.value = 1;
			sortBy.value = "price";
			sortDir.value = "ASC";
			filters.value = {};
			gettingSub.value = true;
			setSubCategories();
			getProducts(refresherEvent, true);
		}

		return {
			t,
			storeX,
			opensortby,
			doRefresh,
			icons,
			getProducts,
			getImageUrl,
			getBannerImageUrl,
			goToProduct,
			category,
			products,
			categoryId,
			subCategories,
			gettingSub,
			gettingProducts,
			filters,
			sortAttributes,
			navToHome,
			sortingAction,
			availableFilters,
			changeCategory,
			selectedFilters,
			selectedFiltersLabels,
			filterAction,
			cancelFilterAction,
			clearFilterItem,
			filterChanged,
			getUrl,
			getFilterItems,
			maxFilterItems,
			currentPage,
			allProductsCount,
			pages,
			notifyEmptyActive,
			categoryNotifyHasProductsEmail,
			categoryNotifyHasProducts,
		};
	},
	components: {
		IonPage,
		IonContent,
		IonText,
		IonItem,
		IonRadioGroup,
		IonRadio,
		MainHeader,
		MainFooter,
		ProductsList,
		IonIcon,
		IonInfiniteScroll,
		IonInfiniteScrollContent,
		Slider,
		Banner,
	},
});
